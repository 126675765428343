<template>
  <div class="hello">
    <!-- <h1>👋</h1> -->
    <p>Hello! I'm Davie and it's nice to e-meet you! 👋</p>
    <p>I'm a tech entrepreneur in Canada with a particular interest in Govtech. 🇨🇦</p>
    <p>I enjoy reading non-fiction books 📚, boxing 🥊, and building web projects that usually go nowhere. 💩</p>
    <p>Feel free to connect with me on 
      <a href="https://www.linkedin.com/in/davielee/" target="_blank" rel="noopener">LinkedIn</a>.
    </p>
    <h3>Employer(s)</h3>
    <ul>
      <li><a href="https://www.techalliance.ca/techalliance-advisory-solutions/advisory-services/" target="_blank" rel="noopener">TechAlliance</a></li>
      <li><a href="https://www.inter-val.ai/company" target="_blank" rel="noopener">interVal</a></li>
    </ul>
    <h3>Angel Investment(s)</h3>
    <ul> 
      <li><a href="https://www.inter-val.ai/" target="_blank" rel="noopener">interVal</a></li>
      <li><a href="https://www.tailwindsuccess.com/" target="_blank" rel="noopener">Tailwind</a></li>      
    </ul>
    <h3>Current Idea(s)</h3>
    <ul>      
      <li>Pilarra</li>      
      <li>Munispend</li>
      <li><a href="https://opendisciplines.netlify.app" target="_blank" rel="noopener">Open Disciplines</a></li>
    </ul>
    <h3>Exit(s)</h3>
    <ul>
      <li><a href="https://catalisgov.com/solutions/citizen-engagement/economic-development-dashboard/" target="_blank" rel="noopener">Townfolio</a> <i>(acquired by Catalis)</i></li>
    </ul>
    <br><br>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #2e86de;
}
</style>
